<template>
  <div>
    <b-button
      type="button"
      variant="primary"
      class="mb-2"
      :to="{ name: 'alerts-create' }"
    >
      <span class="text-nowrap">Creer une Alerte</span>
    </b-button>
    <b-card no-body>
      <alerts-filter
        :platform-filter.sync="platformFilter"
        :platform-options="platformOptions"
      />
      <alerts-list
        :api-url="apiUrl"
        :platform-filter="platformFilter"
        :table-columns="tableColumns"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard,
} from 'bootstrap-vue'
import AlertsFilter from '@/components/lists/alerts/AlertsFilter.vue'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import AlertsList from '@/components/lists/alerts/AlertsList.vue'

export default {
  name: 'Alerts',
  components: {
    BCard,
    BButton,
    AlertsFilter,
    AlertsList,
  },
  data() {
    return {
      platformFilter: null,
      platformOptions: [],
      tableColumns: [
        {
          key: 'text',
          label: 'Contenu',
          sortable: true,
        },
        {
          key: 'platforms_count',
          label: 'Nombre de plateformes',
          sortable: true,
        },
        {
          key: 'is_visible',
          label: 'Visibilité',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Date de création',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      apiUrl: 'alerts',
    }
  },
  mounted() {
    this.fetchPlatforms()
  },
  methods: {
    async fetchPlatforms() {
      try {
        const { data } = await axiosIns.get('/platforms/minimal')
        data.forEach(platform => {
          this.platformOptions.push({ label: platform.name, value: platform.uuid })
        })
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
