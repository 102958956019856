<template>
  <div>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Afficher</label>
          <v-select
            v-model="perPage"
            :dir="'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            @input="fetchAlerts(true)"
          />
          <label>entrées</label>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form @submit.prevent="fetchAlerts(true)">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Rechercher..."
              />
              <b-button
                type="submit"
                variant="primary"
              >
                <span class="text-nowrap">Chercher</span>
              </b-button>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refAlertListTable"
      class="position-relative"
      :items="alerts"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Aucun résultat trouvé"
      :sort-desc.sync="isSortDirDesc"
      :no-local-sorting="true"
      :busy="loading"
      @sort-changed="sortAlerts($event)"
    >
      <!-- busy -->
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner label="Loading..." />
        </div>
      </template>

      <!-- Column: is_visible -->
      <template #cell(is_visible)="data">
        <div class="text-nowrap">
          <b-badge :variant="(data.item.is_visible ? 'light-success' : 'light-danger')">
            {{ data.item.is_visible }}
          </b-badge>
        </div>
      </template>

      <!-- Column: platforms_count -->
      <template #cell(platforms_count)="data">
        <div class="text-nowrap">
          <feather-icon
            icon="LayoutIcon"
            size="18"
            class="mr-50"
          />
          <span class="align-text-top text-capitalize">{{
            data.item.platforms_count
          }}</span>
        </div>
      </template>

      <!-- Column: created_at -->
      <template #cell(created_at)="data">
        <span>{{ moment(data.value).format("LLL") }}</span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`alert-row-${data.item.uuid}-show-icon`"
            icon="EyeIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'alerts-view', params: { id: data.item.uuid }})"
          />
          <b-tooltip
            title="Détails"
            class="cursor-pointer"
            :target="`alert-row-${data.item.uuid}-show-icon`"
          />

          <feather-icon
            :id="`alert-row-${data.item.uuid}-edit-icon`"
            icon="EditIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="$router.push({ name: 'alerts-edit', params: { id: data.item.uuid }})"
          />
          <b-tooltip
            title="Éditer"
            class="cursor-pointer"
            :target="`alert-row-${data.item.uuid}-edit-icon`"
          />

          <feather-icon
            :id="`alert-row-${data.item.uuid}-delete-icon`"
            v-b-modal="`modal-delete-alert-${data.item.uuid}`"
            icon="TrashIcon"
            class="cursor-pointer"
            size="16"
          />
          <b-tooltip
            title="Supprimer"
            class="cursor-pointer"
            :target="`alert-row-${data.item.uuid}-delete-icon`"
          />
          <b-modal
            :id="`modal-delete-alert-${data.item.uuid}`"
            :ref="`modal-delete-alert-${data.item.uuid}`"
            ok-variant="danger"
            ok-title="Supprimer"
            modal-class="modal-danger"
            centered
            title="Suppression de l'alerte"
          >
            <b-card-text>
              Souhaitez vous vraiment supprimer cette alerte ?
              <p class="text-danger font-weight-bold">
                (CETTE ACTION EST IRRÉVERSIBLE)
              </p>
            </b-card-text>
            <template #modal-footer>
              <b-button
                variant="secondary"
                @click="hideModal(`modal-delete-alert-${data.item.uuid}`)"
              >
                annuler
              </b-button>
              <b-button
                variant="danger"
                :disabled="actionLoading"
                @click="deleteAlert(data.item.uuid)"
              >
                <b-spinner
                  v-show="actionLoading"
                  small
                />
                supprimer
              </b-button>
            </template>
          </b-modal>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
            {{ dataMeta.total }} entrées</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalAlerts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="changePage($event)"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BButton, BCardText,
  BCol,
  BForm,
  BFormInput, BLink, BModal, BPagination,
  BRow, BSpinner, BBadge,
  BTable, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AlertsList',
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    BForm,
    vSelect,
    BModal,
    BCardText,
    BTooltip,
    BLink,
    BBadge,
  },
  props: {
    platformFilter: {
      type: [String, null],
      default: null,
    },
    trashedFilter: {
      type: [Boolean, null],
      default: null,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    tableColumns: {
      type: [Array, null],
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      actionLoading: false,
      perPageOptions: [5, 25, 50, 100],
      perPage: 25,
      searchQuery: '',
      sortBy: 'created_at',
      isSortDirDesc: true,
      alerts: [],
      currentPage: 1,
      totalAlerts: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
        total: 0,
      },
    }
  },
  watch: {
    trashedFilter: {
      handler() {
        this.fetchAlerts(true)
      },
    },
    platformFilter: {
      handler() {
        this.fetchAlerts(true)
      },
    },
  },
  mounted() {
    this.fetchAlerts()
  },
  methods: {
    async fetchAlerts(clearPagination = false) {
      try {
        this.loading = true
        const { data } = await axiosIns.get(this.apiUrl, {
          params: {
            onlyTrashed: this.trashedFilter,
            term: this.searchQuery,
            page: clearPagination ? 1 : this.currentPage,
            perPage: this.perPage,
            sortBy: this.sortBy,
            sortDirection: this.isSortDirDesc ? 'desc' : 'asc',
            platform_id: this.platformFilter,
          },
        })
        this.alerts = data.data
        this.dataMeta.from = data.meta.from
        this.dataMeta.to = data.meta.to
        this.dataMeta.total = data.meta.total
        this.totalAlerts = data.meta.total
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async deleteAlert(uuid) {
      try {
        this.actionLoading = true
        await axiosIns.delete(`alerts/${uuid}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'L\' alerte a été supprimée',
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
        this.hideModal(`modal-delete-alert-${uuid}`)
        this.fetchAlerts()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
      }
    },
    sortAlerts(e) {
      this.isSortDirDesc = e.sortDesc
      this.sortBy = e.sortBy
      this.fetchAlerts()
    },
    changePage(e) {
      this.currentPage = e
      this.fetchAlerts()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
